import React from 'react';
import './GroupCodeBanner.css';

const GroupCodeBanner = ({group_access_code}) => {
  console.log('>> GroupCodeBanner group_access_code:', group_access_code);
  return (
    <div className="groupCodeBanner">
      <div className="groupCodeLabel">Group Code&nbsp;&nbsp;</div>
      <div className="groupCode">{group_access_code}</div>
    </div>
  );
};

export default GroupCodeBanner;