import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './ResultsPage.css';

const ResultsPage = () => {
  const location = useLocation();
  const { userAnswers } = location.state || {};

  const [currentIndex, setCurrentIndex] = useState(0);
  const [results, setResults] = useState([]);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState(null);

  const fetchResults = async (userAnswers) => {
    const url_main = process.env.REACT_APP_API_URL;
    const access_token = process.env.REACT_APP_API_ACCESS_TOKEN;

    if (!url_main || !access_token) {
      console.error('API URL or Access Token is missing');
      return;
    }

    const url = `${url_main}/api/questions/submit`;
    const headers = {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    };

    const payload = {
      data: JSON.stringify(userAnswers),
      mode: 'submit',
      user_state_uuid: '1234',
      page_number: 1
    };

    try {
      const response = await axios.post(url, payload, { headers, withCredentials: true });
      setResults(response.data.movie_recommendations);
    } catch (error) {
      console.error('Error fetching results:', error);
    }
  };

  useEffect(() => {
    if (userAnswers) {
      fetchResults(userAnswers);
    }
  }, [userAnswers]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % results.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + results.length) % results.length);
  };

  const getCardClass = (index) => {
    const diff = (index - currentIndex + results.length) % results.length;
    if (diff === 0) return 'active';
    if (diff === 1) return 'next-adjacent';
    if (diff === results.length - 1) return 'prev-adjacent';
    return 'hidden';
  };

  const titleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const yearOnly = (str) => {
    return str ? str.split('-')[0] : '';
  };

  const toggleOverlay = (movie = null) => {
    setSelectedMovie(movie);
    setOverlayVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className="results-page">
      <h1 className="title">Movie Recommendations</h1>
      <p className="subtitle">Based on your preferences, here are some movies you might enjoy.</p>
      <div className="carousel">
        {results.map((movie, index) => (
          <div
            key={index}
            className={`carousel-item ${getCardClass(index)}`}
            onClick={() => toggleOverlay(movie)}
          > 
            <div className="card">
              <div className="card-image-container">
                <img 
                  src={movie.poster_path ? `https://image.tmdb.org/t/p/w185${movie.poster_path}` : 'path/to/placeholder-image.jpg'}
                  alt={movie.title}
                  className="card-image"
                />
              </div>
              <div className="card-content">
                <h3>{movie.title}</h3>
                <p>{movie.genres.map(titleCase).join(', ') || ''}</p>
                <p>{yearOnly(movie.release_date) || 'Release date not available'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="carousel-controls">
          <button onClick={prevSlide} className="carousel-button left-button" aria-label="Previous slide">&lt;</button>
          <button onClick={nextSlide} className="carousel-button right-button" aria-label="Next slide">&gt;</button>
      </div>
      {overlayVisible && selectedMovie && (
        <div className="overlay">
          <button className="close-button" onClick={() => toggleOverlay(null)} aria-label="Close overlay">X</button>
          <div className="overlay-content">
            <div
              className="backdrop-image"
              style={{
                backgroundImage: `url(${selectedMovie.backdrop_path ? `https://image.tmdb.org/t/p/w780${selectedMovie.backdrop_path}` : 'path/to/placeholder-image.jpg'})`
              }}
            ></div>
            <img src={selectedMovie.poster_path ? `https://image.tmdb.org/t/p/w185${selectedMovie.poster_path}` : 'path/to/placeholder-image.jpg'} alt={selectedMovie.title} className="overlay-image" />
            <h2>{selectedMovie.title}</h2>
            <p>{selectedMovie.genres.map(titleCase).join(', ') || ''}</p>
            <p><strong>Producer:</strong> {selectedMovie.Producer}</p>
            <p><strong>Description:</strong> {selectedMovie.description || 'No description available'}</p>
            <p><strong>Original Language:</strong> {selectedMovie.original_language.toUpperCase()}</p>
            <p><strong>Release Date:</strong> {yearOnly(selectedMovie.release_date) || 'Release date not available'}</p>
            <p><strong>Adult:</strong> {selectedMovie.adult ? 'Yes' : 'No'}</p>
          </div>
        </div>
      )}
    </div>
  );
} 

export default ResultsPage;