import React from 'react';
import GroupCard from './GroupCard';
import PageFooter from './PageFooter';
import GroupQuestionsPage from './GroupQuestionsPage';
//import { useWebSocket } from '../hooks/useWebSocket';
import { useQuizStart } from '../hooks/useQuizStart';

function GroupCreated({ groupObject }) {
  //const { isConnected, ws } = useWebSocket(groupObject);
  const isConnected = true;
  const { isQuizStarted, handleStartQuiz } = useQuizStart(null, groupObject);

  if (isQuizStarted) {
    return <GroupQuestionsPage groupObject={groupObject} />;
  }

  return (
    <div className="container">
      {isConnected ? (
        <>
          <GroupCard group_object={groupObject} />
          <PageFooter
            group_object={groupObject}
            //websocket={ws}
            handleStartQuiz={handleStartQuiz}
          />
        </>
      ) : (
        <p className="white-box">Connecting to group ...</p>
      )}
    </div>
  );
}

export default GroupCreated;