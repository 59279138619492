import React from 'react';
import './RankQuestion.css';

function RankQuestion({ currentQuestion, handleDragStart, handleDragOver, handleDrop, handleDragEnd }) {
    return (
        <ul id="rankList" className="rankList">
            {currentQuestion.answers.map((answer) => (
                <li
                    className='rank-list-item'
                    key={answer}
                    draggable="true"
                    onDragStart={(e) => handleDragStart(e, answer)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDrop={(e) => handleDrop(e, answer)}
                    onDragEnd={(e) => handleDragEnd(e)}
                >
                    <span>{answer}</span>
                </li>
            ))}
        </ul>
    );
}

export default RankQuestion;