import React from 'react';
import './GroupCard.css';
import GroupCodeBanner from './GroupCodeBanner';
import MembersArea from './MembersArea';

const GroupCard = ({ group_object , post_submit=false }) => {
  console.log('>> GroupCard group_object:', group_object);
  return (
    <div className="card">
      <h2 className="title">{group_object.name}</h2>
      <GroupCodeBanner  group_access_code = {group_object.access_code}/>
      <div className="separator"></div>
      <MembersArea  group_members = {group_object.members} post_submit={post_submit}/>
    </div>
  );
};

export default GroupCard;