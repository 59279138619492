import React, { useState } from 'react';
import '../../ResultsPage/ResultsPage.css';
import './GroupResults.css';
import data from '../hard_coded_result_response.json';
import localImage from './mockup_group_stats.png'; // Adjust the path to your local image

const LocalImageOverlay = ({ onClose }) => {
    return (
      <div className="overlay">
        <button className="close-button" onClick={onClose} aria-label="Close overlay">X</button>
        <div className="overlay-content">
          <img src={localImage} alt="Local Stats" className="overlay-image" />
        </div>
      </div>
    );
  };

const GroupResults = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [results] = useState(data.data);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [showLocalImageOverlay, setShowLocalImageOverlay] = useState(false);

  console.log('>>> GroupResults: results:', results);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % results.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + results.length) % results.length);
  };

  const getCardClass = (index) => {
    const diff = (index - currentIndex + results.length) % results.length;
    if (diff === 0) return 'active';
    if (diff === 1) return 'next-adjacent';
    if (diff === results.length - 1) return 'prev-adjacent';
    return 'hidden';
  };

  const titleCase = (str) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  };

  const yearOnly = (str) => {
    return str ? str.split('-')[0] : '';
  };

  const toggleOverlay = (movie = null) => {
    setSelectedMovie(movie);
    setOverlayVisible((prevVisible) => !prevVisible);
  };

  const toggleLocalImageOverlay = () => {
    setShowLocalImageOverlay((prevVisible) => !prevVisible);
  };

  return (
    <div className="results-page">
      <h1 className="title">Movie Recommendations</h1>
      <p className="subtitle">Based on your preferences, here are some movies you might enjoy.</p>
      <div className="carousel">
        {results.map((movie, index) => (
          <div
            key={index}
            className={`carousel-item ${getCardClass(index)}`}
            onClick={() => toggleOverlay(movie)}
          > 
            <div className="card">
              <div className="card-image-container">
                <div
                  className="backdrop-image"
                  style={{
                    backgroundImage: `url(${movie.backdrop_path ? `https://image.tmdb.org/t/p/w780${movie.backdrop_path}` : 'path/to/placeholder-image.jpg'})`
                  }}
                ></div>
                <img 
                  src={movie.poster_path ? `https://image.tmdb.org/t/p/w185${movie.poster_path}` : 'path/to/placeholder-image.jpg'}
                  alt={movie.title}
                  className="card-image"
                />
              </div>
              <div className="card-content">
                <h3>{movie.title}</h3>
                <p>{movie.genres.map(titleCase).join(', ') || ''}</p>
                <p>{yearOnly(movie.release_date) || 'Release date not available'}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="carousel-controls">
          <button onClick={prevSlide} className="carousel-button left-button" aria-label="Previous slide">&lt;</button>
          <button onClick={nextSlide} className="carousel-button right-button" aria-label="Next slide">&gt;</button>
      </div>
      <button onClick={toggleLocalImageOverlay} className="upwards-button">Group Stats</button>
      {overlayVisible && selectedMovie && (
        <div className="overlay">
          <button className="close-button" onClick={() => toggleOverlay(null)} aria-label="Close overlay">X</button>
          <div className="overlay-content">
            <div
              className="backdrop-image"
              style={{
                backgroundImage: `url(${selectedMovie.backdrop_path ? `https://image.tmdb.org/t/p/w780${selectedMovie.backdrop_path}` : 'path/to/placeholder-image.jpg'})`
              }}
            ></div>
            <img src={selectedMovie.poster_path ? `https://image.tmdb.org/t/p/w185${selectedMovie.poster_path}` : 'path/to/placeholder-image.jpg'} alt={selectedMovie.title} className="overlay-image" />
            <h2>{selectedMovie.title}</h2>
            <p>{selectedMovie.genres.map(titleCase).join(', ') || ''}</p>
            <p><strong>Producer:</strong> {selectedMovie.Producer}</p>
            <p><strong>Description:</strong> {selectedMovie.description || 'No description available'}</p>
            <p><strong>Original Language:</strong> {selectedMovie.original_language.toUpperCase()}</p>
            <p><strong>Release Date:</strong> {yearOnly(selectedMovie.release_date) || 'Release date not available'}</p>
            <p><strong>Adult:</strong> {selectedMovie.adult ? 'Yes' : 'No'}</p>
          </div>
        </div>
      )}
      {showLocalImageOverlay && <LocalImageOverlay onClose={toggleLocalImageOverlay} />}
    </div>
  );
} 

export default GroupResults;