import React from 'react';
import './PageFooter.css';
import { useAuth } from '../../context/AuthContext';

const PageFooter = ({ group_object, websocket, handleStartQuiz }) => {
  const { user } = useAuth();
  const isCreator = group_object.creator_id === user.id;

  return (
    <footer className="footer">
      <button className="button backButton">← Back</button>
      {isCreator ? (
        <button className="button continueButton" onClick={() => handleStartQuiz(websocket)}>
          Start the Quiz →
        </button>
      ) : (
        <div className="waitingMessage">
          <div className="spinner"></div>
          Waiting for the Group Creator to start the quiz
        </div>
      )}
    </footer>
  );
};

export default PageFooter;