import React from 'react';
import './SliderQuestion.css';

function SliderQuestion({ currentQuestion, selectedAnswer, handleSliderChange }) {
    const segmentCount = currentQuestion.answers.length - 1;
    const segmentWidth = 100 / segmentCount;

    return (
        <div className="range-container">
            <input
                type="range"
                min="0"
                max={segmentCount}
                step="1"
                value={selectedAnswer[0] || '2'}
                onChange={handleSliderChange}
                className="modern-slider"
            />
            <div className="range-labels">
                {currentQuestion.answers.map((answer, index) => (
                    <span
                        key={index}
                        className="segment-label"
                        style={{ left: `${index * segmentWidth}%` }}
                    >
                        {answer}
                    </span>
                ))}
            </div>
        </div>
    );
}

export default SliderQuestion;