// src/App.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './App-components/Header';
import Background from './App-components/Background';
import Card from './App-components/Card';
import CreateGroupPage from './CreateGroupPage/CreateGroupPage';
import QuestionsPage from './QuestionsPage/QuestionsPage';
import ResultsPage from './ResultsPage/ResultsPage';
import { AuthProvider } from './context/AuthContext';
import './App.css';

function App() {
  console.log(">> API URL (env variable): ", process.env.REACT_APP_API_URL);
  return (
    <AuthProvider>
      <Router>
        <div className="app">
          <Header />
          <Background>
            <Routes>
              <Route path="/" element={<Card />} />
              <Route path="/create-group" element={<CreateGroupPage />} />
              <Route path="/questions" element={<QuestionsPage />} />
              <Route path="/results" element={<ResultsPage />} />
            </Routes>
          </Background>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;