// components/Card.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

function Card() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [errorMessage, setErrorMessage] = useState('');

  console.log('>> user:', user);

  const handleCreateGroup = () => {
    console.log('>> Creating a group');
    if (!user) {
      setErrorMessage('Please log in to create a group.');
      return;
    }
    navigate('/create-group');
  };

  const handleJoinGroup = () => {
    console.log('>> Joining a group');
    if (!user) {
      setErrorMessage('Please log in to join a group.');
      return;
    }
    // Logic for joining a group
  };

  const handleSingle = () => {
    console.log('>> Navigating to questions');
    navigate('/questions');
  };

  return (
    <div className="card">
      <h1 className="card-title">Hi, there. Let's get started</h1>
      <p className="card-subtitle">
          Choose an option below to begin your journey with us.
      </p>
      <div className="card-buttons">
        <button
          className="create-group"
          onClick={handleCreateGroup}
        >
          Create Group
        </button>
        <button
          className="join-group"
          onClick={handleJoinGroup}
        >
          Join an existing group
        </button>
        <button className="single" onClick={handleSingle}>Single</button>
      </div>
      {errorMessage && <p className="error-message red">{errorMessage}</p>}
    </div>
  );
}

export default Card;