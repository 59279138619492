import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';

export function useQuizStart(ws, groupObject) {
  const [isQuizStarted, setIsQuizStarted] = useState(false);
    const { user } = useAuth();

  const handleStartQuiz = async (websocket) => {
    if (groupObject.creator_id === user.id) {
      // websocket.send(JSON.stringify({ "command": "start_quiz" }));
      // const response = await new Promise((resolve) => {
      //   websocket.onmessage = (event) => {
      //     resolve(JSON.parse(event.data));
      //   };
      // });
      // if (response.data.command === 'start_quiz') {
      //   setIsQuizStarted(true);
      // }
      setIsQuizStarted(true);
    }
  };

  return { isQuizStarted, handleStartQuiz };
}