import React from 'react';
import { useNavigate } from 'react-router-dom';
import useQuestions from './useQuestions';
import './QuestionsPage.css';

function QuestionsPage() {
  const navigate = useNavigate();
  const {
    currentQuestion,
    handleNextClick,
    handlePrevClick,
    renderQuestion,
    loading,
    questions,
    currentQuestionIndex,
    selectedAnswers
  } = useQuestions();

  // TODO : Make a better loading screen
  if (loading) {
    return <div>Loading...</div>;
  }

  if (questions.length === 0) {
    return <div>No questions available.</div>;
  }

  const handleSubmit = () => {
    navigate('/results', { state: { userAnswers: selectedAnswers } });
  };

  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <div className="QuestionsPage">
      <div className="quiz-container">
        <div className="quiz-content">
          <h2>{currentQuestion.question}</h2>
          {renderQuestion()}
        </div>
      </div>
      {isLastQuestion ? (
        <button className="submit-answers right" onClick={handleSubmit}>Submit</button>
      ) : (
        <button className="nav-button right" onClick={handleNextClick}>&gt;</button>
      )}
      <button className="nav-button left" onClick={handlePrevClick}>&lt;</button>

      <div className="progress-dots">
        {questions.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentQuestionIndex ? 'active' : ''}`}
          ></span>
        ))}
      </div>
    </div>
  );
}

export default QuestionsPage;