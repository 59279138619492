import React, { useState } from 'react';

function GroupNameInput({ onSubmit, error }) {
  const [groupName, setGroupName] = useState('');

  const handleInputChange = (e) => {
    setGroupName(e.target.value.slice(0, 30));
  };

  return (
    <div className="group-name-input-card">
      <div className="group-name-input">
        <h1 className='header-text'>Choose a group name</h1>
        <input
          type="text"
          value={groupName}
          onChange={handleInputChange}
          placeholder="Enter group name"
          maxLength="30"
        />
        <button onClick={() => onSubmit(groupName)}>Submit</button>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
}

export default GroupNameInput;