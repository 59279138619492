import { useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';

export function useGroupCreation() {
  const [groupObject, setGroupObject] = useState(null);
  const [error, setError] = useState('');
  const { user } = useAuth();

  const createGroup = async (groupName) => {
    const trimmedGroupName = groupName.trim();

    if (!user) {
      setError('You must be logged in to create a group.');
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/api/group`;
    const headers = {
      'Authorization': `Bearer ${process.env.REACT_APP_API_ACCESS_TOKEN}`,
      'Content-Type': 'application/json'
    };

    const payload = {
      group_name: trimmedGroupName,
      creator_id: user.id,
    };

    try {
      const response = await axios.post(url, payload, { headers, withCredentials: true });

      if (response.status === 201) {
        setGroupObject(response.data.group);
      }
    } catch (error) {
      console.error('Error creating group:', error);
      setError('Failed to create group. Please log in and try again.');
    }
  };

  return { groupObject, createGroup, error };
}