import React from 'react';
import './CreateGroupPage.css';
import GroupNameInput from './components/GroupNameInput';
import GroupCreated from './components/GroupCreated';
import { useGroupCreation } from './hooks/useGroupCreation';

function CreateGroupPage() {
  const { groupObject, createGroup, error } = useGroupCreation();

  if (!groupObject) {
    return <GroupNameInput onSubmit={createGroup} error={error} />;
  }

  return <GroupCreated groupObject={groupObject} />;
}

export default CreateGroupPage;