import { useState, useEffect } from 'react';
import axios from 'axios';

import SingleChoiceQuestion from './QuestionTypes/SingleChoiceQuestion';
import SliderQuestion from './QuestionTypes/SliderQuestion';
import RankQuestion from './QuestionTypes/RankQuestion';

function useQuestions() {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [loading, setLoading] = useState(true);
    const [allQuestionsAnswered, setAllQuestionsAnswered] = useState(false);

    useEffect(() => {
        const fetchQuestions = async () => {
            const url_main = process.env.REACT_APP_API_URL;
            const access_token = process.env.REACT_APP_API_ACCESS_TOKEN;
            const url = `${url_main}/api/questions`;
            const headers = { 'Authorization': `Bearer ${access_token}` };

            try {
                const response = await axios.get(url, { headers });
                console.log('>> Questions:', response.data);
                setQuestions(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching questions:', error);
                setLoading(false);
            }
        };

        fetchQuestions();
    }, []);

    useEffect(() => {
        const allAnswered = questions.every(question => selectedAnswers[question.qid]);
        setAllQuestionsAnswered(allAnswered);
    }, [questions, selectedAnswers]);

    const currentQuestion = questions[currentQuestionIndex] || {};
    const selectedAnswer = selectedAnswers[currentQuestion.qid] || [];

    const handleAnswerClick = (answer) => {
        if (currentQuestion.question_type === 'QuestionTypes-rankq') {
            const updatedAnswers = { ...selectedAnswer };
            updatedAnswers[answer] = updatedAnswers[answer] ? '' : '1';
            setSelectedAnswers({
                ...selectedAnswers,
                [currentQuestion.qid]: updatedAnswers
            });
        } else if (currentQuestion.question_type === 'QuestionTypes-scq') {
            setSelectedAnswers({
                ...selectedAnswers,
                [currentQuestion.qid]: [answer]
            });
        } else if (currentQuestion.question_type === 'QuestionTypes-mcq') {
            const updatedAnswers = selectedAnswer.includes(answer)
                ? selectedAnswer.filter(a => a !== answer)
                : [...selectedAnswer, answer];
            setSelectedAnswers({
                ...selectedAnswers,
                [currentQuestion.qid]: updatedAnswers
            });
        }
    };

    const handleSliderChange = (event) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestion.qid]: [event.target.value]
        });
    };

    const handleRankChange = (answer, rank) => {
        const updatedAnswers = { ...selectedAnswer };
        updatedAnswers[answer] = rank;
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestion.qid]: updatedAnswers
        });
    };

    const handleNextClick = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    // Drag-and-drop functionality
    let draggedItem = null;

    const handleDragStart = (e, answer) => {
        draggedItem = answer;
        e.target.classList.add('dragging');
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    function handleDragEnd(e) {
        e.target.classList.remove('dragging');
    }

    const handleDrop = (e, answer) => {
        e.preventDefault();
        const draggedIndex = currentQuestion.answers.indexOf(draggedItem);
        const droppedIndex = currentQuestion.answers.indexOf(answer);

        // Swap the items in the answers array
        [currentQuestion.answers[draggedIndex], currentQuestion.answers[droppedIndex]] = [currentQuestion.answers[droppedIndex], currentQuestion.answers[draggedIndex]];

        // Update the state to reflect the new order
        setQuestions(questions.map((q, index) => index === currentQuestionIndex ? { ...q, answers: [...currentQuestion.answers] } : q));

        e.target.classList.remove('dragging');
        updateRanks();
    };

    const updateRanks = () => {
        const updatedAnswers = {};
        currentQuestion.answers.forEach((answer, index) => {
            updatedAnswers[answer] = index + 1;
        });
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestion.qid]: updatedAnswers
        });
    };

    const renderQuestion = () => {
        switch (currentQuestion.question_type) {
            case 'QuestionTypes-scq':
                return (
                    <SingleChoiceQuestion
                        currentQuestion={currentQuestion}
                        selectedAnswer={selectedAnswer}
                        handleAnswerClick={handleAnswerClick}
                    />
                );
            case 'QuestionTypes-slq':
                return (
                    <SliderQuestion
                        currentQuestion={currentQuestion}
                        selectedAnswer={selectedAnswer}
                        handleSliderChange={handleSliderChange}
                    />
                );
            case 'QuestionTypes-rankq':
                return (
                    <RankQuestion
                        currentQuestion={currentQuestion}
                        handleDragStart={handleDragStart}
                        handleDragOver={handleDragOver}
                        handleDrop={handleDrop}
                        handleDragEnd={handleDragEnd}
                    />
                );
            default:
                return null;
        }
    };

    return {
        currentQuestion,
        handleAnswerClick,
        handleSliderChange,
        handleRankChange,
        handleNextClick,
        handlePrevClick,
        renderQuestion,
        loading,
        questions,
        currentQuestionIndex,
        allQuestionsAnswered,
        selectedAnswers
    };
}

export default useQuestions;