import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext'; // Adjust the import path as needed
import './SignUp.css';

const SignUp = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);

  const handleSignUp = async (event) => {
    event.preventDefault();
    setLoading(true);

    const usernames = [
      "Happy Unicorn",
      "Sunny Bunny",
      "Cheerful Panda",
      "Jolly Giraffe",
      "Bouncy Kangaroo",
      "Witty Penguin",
      "Zippy Zebra",
      "Peppy Parrot",
      "Merry Elephant",
      "Chipper Chipmunk",
      "Dapper Dolphin",
      "Giggly Goldfish",
      "Joyful Jellyfish",
      "Perky Platypus",
      "Radiant Rhino",
      "Silly Seahorse",
      "Snappy Snail",
      "Sparkly Starfish",
    ];
    const randomUsername = usernames[Math.floor(Math.random() * usernames.length)];
    const randomNumber = Math.floor(Math.random() * 100);
    const randomUsernameWithNumber = randomUsername + ' ' + randomNumber;

    const generateRandomString = (length) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
      }
      return result;
    };

    const email = generateRandomString(10) + "@example.com";
    const password = generateRandomString(16);

    const url_main = process.env.REACT_APP_API_URL;
    const access_token = process.env.REACT_APP_API_ACCESS_TOKEN;

    if (!url_main || !access_token) {
      console.error('API URL or Access Token is missing');
      setLoading(false);
      return;
    }

    const url = `${url_main}/api/user`;
    const headers = {
      'Authorization': `Bearer ${access_token}`,
      'Content-Type': 'application/json'
    };

    const payload = {
      username: randomUsernameWithNumber,
      email: email,
      password: password
    };

    console.log('>> Sign up payload:', payload);
    console.log('>> Sign up url :', url);

    try {
      const response = await axios.post(url, payload, { headers, withCredentials: true });
      console.log('>> Sign up response data :', response.data);
      if (response.data.message === 'success') {
        login(response.data.user);
      }
    } catch (error) {
      console.error('>> Error during sign up:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sign-up-container">
      <button className="sign-up-toggle" onClick={handleSignUp} disabled={loading}>
        {loading ? <div className="spinner"></div> : 'Guest Log In'}
      </button>
    </div>
  );
};

export default SignUp;