import React from 'react';
import './SingleChoiceQuestion.css';

function SingleChoiceQuestion({ currentQuestion, selectedAnswer, handleAnswerClick }) {
    return (
        <div >
            <ul>
                {currentQuestion.answers.map((answer) => (
                    <li
                        key={answer}
                        className={selectedAnswer.includes(answer) ? 'selected' : ''}
                        onClick={() => handleAnswerClick(answer)}
                    >
                        <span>{answer}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default SingleChoiceQuestion;