// components/GroupQuestionsPage.jsx

import React, { useState } from 'react';
import useQuestions from '../../QuestionsPage/useQuestions';
import '../../QuestionsPage/QuestionsPage.css';
//import { useSubmitAnswers } from '../hooks/useSubmitAnswers';
import PostQuestionLobby from './PostQuestionLobby';

function GroupQuestionsPage({ groupObject }) {
  const {
    currentQuestion,
    handleNextClick,
    handlePrevClick,
    renderQuestion,
    loading,
    questions,
    currentQuestionIndex,
    selectedAnswers
  } = useQuestions();

  //const { submitAnswers } = useSubmitAnswers(groupObject);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (questions.length === 0) {
    return <div>No questions available.</div>;
  }

  if (isSubmitting) {
    return <PostQuestionLobby groupObject={groupObject} />;
  }

  const handleSubmit = async () => {
    console.log('>> Answers submitted by user:', selectedAnswers);
    setIsSubmitting(true);

    // try {
    //   const response = await submitAnswers(selectedAnswers);
    //   console.log('>> Server response (GroupQuestionsPage):', response);
    //   // TODO: Handle the response (e.g., show results, navigate to a new page, etc.)
    // } catch (error) {
    //   console.error('Error submitting answers:', error);
    // } finally {
    //   setIsSubmitting(false);
    // }
  };

  const isLastQuestion = currentQuestionIndex === questions.length - 1;

  return (
    <div className="GroupQuestionsPage">
      <div className="quiz-container">
        <div className="quiz-content">
          <h2>{currentQuestion.question}</h2>
          {renderQuestion()}
        </div>
      </div>
      {isLastQuestion ? (
        isSubmitting ? (
          <div className="loader right"></div>
        ) : (
          <button className="submit-answers right" onClick={handleSubmit}>Submit</button>
        )
      ) : (
        <button className="nav-button right" onClick={handleNextClick}>&gt;</button>
      )}
      <button className="nav-button left" onClick={handlePrevClick}>&lt;</button>
    </div>
  );
}

export default GroupQuestionsPage;