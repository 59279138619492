import React, { useState } from 'react';
import './MembersArea.css';

const MembersArea = ({ group_members, post_submit = false }) => {
  const [members, setMembers] = useState(group_members || []);
  console.log('MembersArea: members', members);
  console.log('MembersArea: post_submit', post_submit);

  const addUser = () => {
    const newUser = { username: 'new_user', loading: true }; // Hardcoded user for demo purposes
    setMembers([...members, newUser]);
  };

  return (
    <div className="membersArea">
      {members.map((member, index) => (
        <div key={index} className="member">
          <div className="profile-picture">
            {post_submit ? (
              member.loading ? (
                <div className="spinner"></div>
              ) : (
                <span className="green-tick">✔</span>
              )
            ) : (
              <div className="profile-picture"></div>
            )}
          </div>
          <div className="member-username">{member.username}</div>
        </div>
      ))}
      <button className="add-user-button" onClick={addUser}>Demo: Add User</button>
    </div>
  );
};

export default MembersArea;