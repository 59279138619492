import React from 'react';
import { useState } from 'react';
import GroupCard from './GroupCard';
//import { useWebSocket } from '../hooks/useWebSocket';
import './PostQuestionLobby.css';
import GroupResults from './GroupResults';

function PostQuestionLobby({ groupObject }) {
  //const { isConnected, ws } = useWebSocket(groupObject);
  console.log('>>> Post Question Lobby: groupObject:', groupObject);
  const isConnected = true;
  console.log('>>> Post Question Lobby: isConnected:', isConnected);
  const [showResults, setShowResults] = useState(false); // Corrected destructuring
  console.log('>>> Post Question Lobby: groupObject:', groupObject);

  const handleAdminDebugClick = () => {
    console.log('Admin Debug Button Clicked');
    setShowResults(true);
  };

  if (showResults) {
    return (
      <div className="container">
        <GroupResults />
      </div>
    );
  }

  return (
    <div className="container">
      {isConnected ? (
        <>
          <GroupCard group_object={groupObject} post_submit={true}/>
        </>
      ) : (
        <p className="white-box">Connecting to group ...</p>
      )}
      <button 
        className="admin-debug-button" 
        onClick={handleAdminDebugClick}
      >
        Demo: Force Results
      </button>
    </div>
  );
}

export default PostQuestionLobby;