import React from 'react';
import './Header.css';
// import SignIn from './Sign-in-sign-up/SignIn';
import SignUp from './Sign-in-sign-up/SignUp';
import { useAuth } from '../context/AuthContext.js';

function Header() {
  const { user } = useAuth();

  // const handleLogout = () => {
  //   if (window.confirm('Are you sure you want to log out?')) {
  //     logout();
  //   }
  // };

  return (
    <header className="header">
      <div className="header-left">
        {/* Add any left-side content here, if any */}
      </div>
      <div className="header-right">
        <div className="content-container">
          {user ? (
            <div className="user-profile">
              <img src="https://via.placeholder.com/32/000000/000000?text=+" alt="Profile" />
              <span>{user.username}</span>
              {/* <button className='logout-button' onClick={handleLogout}>Log Out</button> */}
            </div>
          ) : (
            <div className="header-buttons">
              {/* <SignIn /> */}
              <SignUp />
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;